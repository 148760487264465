import React from 'react'
import { PageContext, Service } from '../lib/sanity-types'
import { DetailPage } from '../components/detail-page'

const ServicePage: React.FC<{
  pageContext: PageContext & { service: Service }
}> = ({ pageContext }) => {
  return (
    <DetailPage
      related={pageContext.service.related}
      seo={pageContext.service.seo}
      pageContext={pageContext}
      name={pageContext.service.name}
      description={pageContext.service.description}
      content={pageContext.service.content}
    />
  )
}

export default ServicePage
